import React, { Component } from 'react';
import axios from 'axios';
import { Route, Routes } from 'react-router-dom';

import { HeaderBar } from '../headerBar';
import { UnauthorizedHeaderBar } from '../unauthorizedHeaderBar';
import { Login } from '../login';
import { Forbidden } from '../403';
import { RequireAuth } from '../requireAuth';
import { Chat } from '../chat';
import { getAuthToken } from '../../services';
import { Localization } from '../localization';
import { ChatExecutors } from '../chatExecutors';
import { Orders } from '../orders';
import { Category } from '../category';
import { CategoryEditor } from '../categoryEditor';
import { ServiceEditor } from '../serviceEditor';
import { Service } from '../service';
import { UnauthorizedContractors } from '../unauthorizedContractors';
import { EditUnauthorizedContractor } from '../unauthorizedContractors/editUnauthorizedContractor';
import { AuthorizedContractors } from '../authorizedContractors';
// import { makeStyles } from '@mui/styles';

export let axiosInstance = axios.create({
  headers: {
    common: {
      Authorization: `token=${getAuthToken()}`
    }
  }
});

interface Props {}

interface State {}

// const useStyles = makeStyles({ color_style: { backgroundColor: 'red' } });

export class App extends Component<Props, State> {
  constructor(props: Readonly<Props> | Props) {
    super(props);
    this.state = {};
  }
  // classes = useStyles();
  render() {
    return (
      <Routes>
        <Route
          path="/"
          element={
            <RequireAuth>
              <HeaderBar />
            </RequireAuth>
          }
        />
        <Route
          path="/chat"
          element={
            <RequireAuth>
              <>
                <HeaderBar />
                <Chat />
              </>
            </RequireAuth>
          }
        />
        <Route
          path="/chatExecutors"
          element={
            <RequireAuth>
              <>
                <HeaderBar />
                <ChatExecutors />
              </>
            </RequireAuth>
          }
        />
        <Route
          path="/localization"
          element={
            <RequireAuth>
              <>
                <HeaderBar />
                <Localization />
              </>
            </RequireAuth>
          }
        />
        <Route
          path="/orders"
          element={
            <RequireAuth>
              <>
                <HeaderBar />
                <Orders />
              </>
            </RequireAuth>
          }
        />
        <Route
          path="/category"
          element={
            <RequireAuth>
              <>
                <HeaderBar />
                <Category />
              </>
            </RequireAuth>
          }
        />
        <Route
          path="category/:id/editor"
          element={
            <RequireAuth>
              <>
                <HeaderBar />
                <CategoryEditor />
              </>
            </RequireAuth>
          }
        />
        <Route
          path="/services"
          element={
            <RequireAuth>
              <>
                <HeaderBar />
                <Service />
              </>
            </RequireAuth>
          }
        />
        <Route
          path="/service/:id/editor"
          element={
            <RequireAuth>
              <>
                <HeaderBar />
                <ServiceEditor />
              </>
            </RequireAuth>
          }
        />
        <Route
          path="/login"
          element={
            <>
              <UnauthorizedHeaderBar />
              <Login />
            </>
          }
        />
        <Route
          path="/unauthorized-contractors"
          element={
            <RequireAuth>
              <>
                <HeaderBar />
                <UnauthorizedContractors />
              </>
            </RequireAuth>
          }
        />
        <Route
          path="/authorized-contractors"
          element={
            <RequireAuth>
              <>
                <HeaderBar />
                <AuthorizedContractors />
              </>
            </RequireAuth>
          }
        />
        <Route
          path="/edit-contractor"
          element={
            <RequireAuth>
              <>
                <HeaderBar />
                <EditUnauthorizedContractor />
              </>
            </RequireAuth>
          }
        />
        <Route path="/403" element={<Forbidden />} />
      </Routes>
    );
  }
}
