import React, { FC, useEffect, useState } from 'react';
import { Box, Button, Checkbox, Container, FormControlLabel, MenuItem, TextField, Typography } from '@mui/material';
import has from 'lodash/has';
import { useLocation } from 'react-router-dom';

import { axiosGetCategoryListWithLocale, axiosGetServiceListLocale, getCountryList } from '../../../services';
import { ICategory, ICity, ICountry, IService, IUnauthorizedUser } from '../../types';
import { axiosPutUnauthorizedUser } from '../../../services';

export const EditUnauthorizedContractor: FC = () => {
  const { state } = useLocation();

  const [contractor, setContractor] = useState<IUnauthorizedUser | null>(null);
  const [categoryList, setCategoryList] = useState<ICategory[]>([]);
  const [categoryId, setCategoryId] = useState<number | null>(null);
  const [serviceList, setServiceList] = useState<IService[]>([]);
  const [countryList, setCountryList] = useState<ICountry[]>([]);
  const [cityList, setCityList] = useState<ICity[]>([]);

  useEffect(() => {
    if (has(state, 'user')) {
      axiosGetServiceListLocale(services => {
        setServiceList(services);
        for (let service of services) {
          if (state.user.serviceId === service.id) {
            setCategoryId(service.categoryId);
            break;
          }
        }
      });
      axiosGetCategoryListWithLocale(categoryList => setCategoryList(categoryList));
      getCountryList('ru').then(_countryList => {
        setCountryList(_countryList);
        for (let country of _countryList) {
          if (state.user.countryId === country.id) {
            setCityList(country.cities);
            break;
          }
        }
      });
      setContractor(state.user);
    }
  }, []);

  const onChangeCategory = (value: string) => {
    const id = parseInt(value, 10);
    setCategoryId(id);
  };

  const onChangeService = (value: any) => {
    const id = parseInt(value, 10);
    setContractor(prev => (prev != null ? { ...prev, serviceId: id } : prev));
  };
  const onChangeCountry = (value: string) => {
    const id = parseInt(value, 10);
    setContractor(prev => (prev != null ? { ...prev, countryId: id, cityId: null } : prev));
    for (let country of countryList) {
      if (id === country.id) {
        setCityList(country.cities);
        break;
      }
    }
  };

  const onChangeCity = (value: any) => {
    const id = parseInt(value, 10);
    setContractor(prev => (prev != null ? { ...prev, cityId: id } : prev));
  };
  const setName = (name: string) => {
    setContractor(prev => (prev != null ? { ...prev, name } : prev));
  };
  const setInfo = (info: string) => {
    setContractor(prev => (prev != null ? { ...prev, info } : prev));
  };

  const handleCheckedWholeCountry = () =>
    setContractor(prev => (prev != null ? { ...prev, availableNationwide: !prev.availableNationwide } : prev));

  const onSave = () => {
    if (contractor != null) {
      axiosPutUnauthorizedUser({ ...contractor }, (status: boolean) => {});
    }
  };

  const onToggleChecked = () => {
    setContractor(prev => (prev != null ? { ...prev, state: prev.state === 1 ? 0 : 1 } : prev));
  };

  return (
    <Container
      maxWidth={false}
      style={{
        padding: '0 30px',
        marginTop: '120px',
        minWidth: '1200px'
      }}>
      <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between', minWidth: '900px' }}>
        <Box sx={{ minWidth: '100px' }}>
          <Typography>Имя/Фирма</Typography>
          <TextField
            sx={{ mr: '30px', pt: '15px' }}
            value={contractor?.name}
            variant={'standard'}
            size={'small'}
            multiline={true}
            InputProps={{
              style: {
                marginTop: '14px'
              }
            }}
            onChange={e => setName(e.target.value)}
          />
        </Box>
        <Box sx={{ minWidth: '100px' }}>
          <Typography>Контактные данные</Typography>
          <TextField
            value={contractor?.info}
            sx={{ mr: '30px', pt: '15px' }}
            variant={'standard'}
            size={'small'}
            multiline={true}
            InputProps={{
              style: {
                marginTop: '14px'
              }
            }}
            onChange={e => setInfo(e.target.value)}
          />
        </Box>

        <Box sx={{ minWidth: '100px', mr: '30px' }}>
          <Typography>Страна</Typography>
          {countryList.length > 0 && (
            <TextField
              onChange={event => onChangeCountry(event.target.value)}
              value={contractor?.countryId}
              sx={{ minWidth: '100px', pt: '22px' }}
              size="small"
              select
              variant="standard">
              {countryList.map(({ id, name }) => (
                <MenuItem key={`country-${id}`} value={id}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Box>

        <Box sx={{ minWidth: '200px', mr: '30px' }}>
          <Box
            sx={{
              backgroundColor: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              mb: '10px'
            }}>
            {contractor?.availableNationwide ? (
              <Box
                onClick={handleCheckedWholeCountry}
                component="img"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '4px',
                  border: '2px solid #00000099',
                  width: '22px',
                  height: '22px',
                  mr: '9px'
                }}
                src={'/check.svg'}
              />
            ) : (
              <Box
                onClick={handleCheckedWholeCountry}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '4px',
                  border: '2px solid #00000099',
                  width: '22px',
                  height: '22px',
                  mr: '9px'
                }}
              />
            )}
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 400,
                letterSpacing: 0.15,
                mr: '3px'
              }}>
              По всей стране
            </Typography>
          </Box>
          <Typography>Город</Typography>
          {cityList.length > 0 && (
            <TextField
              onChange={event => onChangeCity(event.target.value)}
              value={contractor?.cityId ?? ''}
              disabled={contractor?.availableNationwide}
              sx={{ minWidth: '200px', pt: '22px' }}
              size="small"
              select
              variant="standard">
              {cityList.map(({ id, name }) => (
                <MenuItem key={`category-${id}`} value={id}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'flex-end', mt: '40px' }}>
        <Typography>Категория: </Typography>
        <TextField
          onChange={event => onChangeCategory(event.target.value)}
          value={categoryId ?? ''}
          sx={{ pt: '22px', ml: '40px' }}
          size="small"
          select
          variant="standard">
          {categoryList.map(({ id, locale }) => (
            <MenuItem key={`category-${id}`} value={id}>
              {locale[0].value}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'flex-end', mt: '40px' }}>
        <Typography>Сервис: </Typography>
        <TextField
          onChange={event => onChangeService(event.target.value)}
          value={contractor?.serviceId ?? ''}
          sx={{ pt: '21px', ml: '40px' }}
          size="small"
          select
          variant="standard">
          {serviceList?.map(
            ({ id, locale, categoryId: serviceCategoryId }) =>
              categoryId === serviceCategoryId && (
                <MenuItem key={`service-${id}`} value={id}>
                  {locale[0].value}
                </MenuItem>
              )
          )}
        </TextField>
      </Box>
      <FormControlLabel
        sx={{ mt: '20px' }}
        control={<Checkbox />}
        label="Скрыть подрядчика"
        checked={contractor?.state === 0}
        onChange={onToggleChecked}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: '40px' }}>
        <Button sx={{ backgroundColor: 'green' }} variant="contained" onClick={onSave}>
          Сохранить
        </Button>
      </Box>
    </Container>
  );
};
