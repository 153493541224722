import React, { Component } from 'react';
import { Box, Button, Input } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

interface Props {
  onAdd: (key: string, ru: string) => void;
}

interface State {
  key: string;
  ru: string;
}

export class InputHeader extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      key: '',
      ru: ''
    };
  }

  onChangeKey = (value: string) => this.setState(() => ({ key: value }));

  onChangeRu = (value: string) => this.setState(() => ({ ru: value }));

  onAdd = (key: string, ru: string) => {
    this.setState({ key: '', ru: '' });
    this.props.onAdd(key, ru);
  };

  render() {
    const { key, ru } = this.state;
    return (
      <Box sx={{ display: 'flex' }}>
        <Input
          value={key}
          onChange={event => this.onChangeKey(event.target.value)}
          disableUnderline
          placeholder="ключ"
          sx={{ border: '1px solid black', borderRadius: '7px', mr: '10px' }}
        />
        <Input
          value={ru}
          onChange={event => this.onChangeRu(event.target.value)}
          disableUnderline
          placeholder="значение на русском"
          sx={{ border: '1px solid black', borderRadius: '7px', mr: '10px' }}
        />
        <Button
          variant="contained"
          startIcon={<AddCircleOutlineIcon />}
          sx={{ height: '100%' }}
          onClick={() => this.onAdd(key, ru)}>
          Добавить
        </Button>
      </Box>
    );
  }
}
