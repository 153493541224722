import { TableHead, TableRow } from '@mui/material';
import React, { PureComponent } from 'react';
import { HeaderCell } from '../../../headerCell';

export class TableHeader extends PureComponent {
  render() {
    return (
      <TableHead
        sx={{
          backgroundColor: 'white',
          position: 'sticky',
          top: 0,
          zIndex: +1
        }}>
        <TableRow sx={{ borderBottom: '1px solid black' }}>
          <HeaderCell width="11%" align="left">
            Ключ
          </HeaderCell>
          <HeaderCell width="17%" align="left">
            RU
          </HeaderCell>
          <HeaderCell width="17%" align="left">
            EN
          </HeaderCell>
          <HeaderCell width="17%" align="left">
            ES
          </HeaderCell>
          <HeaderCell width="17%" align="left">
            FR
          </HeaderCell>
          <HeaderCell width="17%" align="left">
            CA
          </HeaderCell>
          <HeaderCell width="4%" align="left"></HeaderCell>
        </TableRow>
      </TableHead>
    );
  }
}
