import React, { Component } from 'react';
import { Box, Button, Container, Table, TableBody, TableContainer } from '@mui/material';
import { v4 as uuid } from 'uuid';

import { axiosInstance } from '../app';
import { removeAuthToken } from '../../services';
import { InputHeader } from './components/InputHeader';
import { Row } from './components/Row';
import { TableHeader } from './components/tableHeader';

import env from '../../environment.json';

interface Props {}

interface State {
  data: {
    id: string;
    key: string;
    value: {
      ru: string;
      en: string;
      es: string;
      fr: string;
      ca: string;

    };
  }[];
}

export class Localization extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      data: []
    };
  }

  componentDidMount() {
    axiosInstance
      // @ts-ignore
      .get(`${env[env.environment].host}/v1/localization`)
      .then(response => {
        const data = response.data.words;
        data.forEach((elem: Record<string, any>) => {
          elem.id = uuid();
        });
        this.setState(() => ({ data: data }));
      });
  }

  onSave() {
    axiosInstance
      // @ts-ignore
      .post(`${env[env.environment].host}/v1/localization`, { words: [...this.state.data] })
      .catch(() => {
        removeAuthToken();
        window.location.reload();
      });
  }

  onAdd = (key: string, ru: string) =>
    this.setState(prev => ({
      data: [
        ...prev.data,
        {
          id: uuid(),
          key: key,
          value: {
            ru: ru,
            en: '',
            es: '',
            fr: '',
            ca: ''
          }
        }
      ]
    }));

  onChange = (value: string, lang: string, id: string) => {
    const index = this.state.data.findIndex(el => el.id === id);
    this.setState(prev => ({
      data: [
        ...prev.data.slice(0, index),
        {
          ...prev.data[index],
          value: {
            ...prev.data[index].value,
            [lang]: value
          }
        },
        ...prev.data.slice(index + 1)
      ]
    }));
  };

  onDelete = (id: string) => {
    const index = this.state.data.findIndex(el => el.id === id);
    this.setState(prev => ({
      data: [...prev.data.slice(0, index), ...prev.data.slice(index + 1)]
    }));
  };

  render() {
    return (
      <Container maxWidth={false} sx={{ maxHeight: '100vh', marginTop: '80px' }}>
        <Box sx={{ display: 'flex', flex: 1, justifyContent: 'space-between', mt: '20px' }}>
          <InputHeader onAdd={(key, ru) => this.onAdd(key, ru)} />
          <Button variant="contained" sx={{ background: 'green', ml: '30px' }} onClick={() => this.onSave()}>
            Сохранить изменения
          </Button>
        </Box>
        <TableContainer
          sx={{
            border: '1px solid black',
            mt: '35px',
            maxHeight: 'calc(100vh - 190px)',
            minHeight: 'calc(100vh - 190px)',
            overflow: 'auto',
            p: '0 0 0 10px'
          }}>
          <Table>
            <TableHeader />
            <TableBody sx={{ maxHeight: '100% -56.8px ' }}>
              {this.state.data.map(word => (
                <Row
                  id={word.id}
                  key={word.id}
                  en={word.value.en}
                  es={word.value.es}
                  ru={word.value.ru}
                  fr={word.value.fr}
                  ca={word.value.ca}
                  keyValue={word.key}
                  onChange={this.onChange}
                  onDelete={this.onDelete}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    );
  }
}
