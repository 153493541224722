import React, { FC, useEffect, useState } from 'react';
import { DateTime } from 'luxon';

import {
  Button,
  Container,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';

import { IAuthorizedUser } from '../types';
import { axiosGetAuthorizedUserList, axiosPutAuthorizedUser } from '../../services';

export const HeaderCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.root}`]: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '17px',
    color: '#1B1F3B',
    opacity: 0.65,
    padding: '10px 8px 16px 8px'
  }
}));

export const BodyCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.root}`]: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '17px',
    lineHeight: '24px',
    color: '#1B1F3B',
    opacity: 0.8,
    padding: '10px 8px'
  }
}));

export const AuthorizedContractors: FC = () => {
  const [userList, setUserList] = useState<IAuthorizedUser[]>([]);

  useEffect(() => {
    axiosGetAuthorizedUserList(data => setUserList(data));
  }, []);

  const putUser = (id: number, state: number, index: number) => {
    axiosPutAuthorizedUser(id, state, status => {
      if (status) {
        setUserList(prev => [
          ...prev.slice(0, index),
          {
            ...prev[index],
            state
          },
          ...prev.slice(index + 1)
        ]);
      }
    });
  };

  return (
    <Container
      maxWidth={false}
      style={{
        padding: '0 30px',
        marginTop: '90px'
      }}>
      <TableContainer
        sx={{
          maxHeight: 'calc(100vh - 100px)',
          minHeight: 'calc(100vh - 100px)',
          overflow: 'auto',
          margin: 0
        }}>
        <Table>
          <TableHead
            sx={{
              backgroundColor: 'white',
              position: 'sticky',
              top: 0,
              zIndex: 1
            }}>
            <TableRow>
              <HeaderCell width="5%" align="left">
                Id
              </HeaderCell>
              <HeaderCell width="5%" align="left">
                Провайдер
              </HeaderCell>
              <HeaderCell width="5%" align="left">
                Email
              </HeaderCell>
              <HeaderCell width="5%" align="left">
                Телефон
              </HeaderCell>
              <HeaderCell width="5%" align="left">
                Имя
              </HeaderCell>
              <HeaderCell width="15%" align="left">
                Местоположение
              </HeaderCell>
              <HeaderCell width="55%" align="left">
                Сервисы
              </HeaderCell>
              <HeaderCell width="5%" align="left">
                Дата регистрации
              </HeaderCell>
              <HeaderCell width="5%" align="left"></HeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userList?.map((user, index) => (
              <TableRow
                sx={{
                  backgroundColor: user.state === 0 ? '#D7D7D7' : 'white',
                  opacity: user.state === 0 ? 0.5 : 1
                }}
                key={`user-${user.id}`}>
                <BodyCell align="left">
                  <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'black', opacity: 0.9 }}>
                    {user.id}
                  </Typography>
                </BodyCell>
                <BodyCell align="left">
                  <Typography>{user.provider}</Typography>
                </BodyCell>
                <BodyCell align="left">
                  <Typography>{user.email}</Typography>
                </BodyCell>
                <BodyCell align="left">
                  <Typography>{user.phone}</Typography>
                </BodyCell>
                <BodyCell align="left">
                  <Typography>{user.name}</Typography>
                </BodyCell>
                <BodyCell align="left">
                  <Typography> {user.countryName}</Typography>
                  <Typography>{user.cityName}</Typography>
                </BodyCell>
                <BodyCell align="left">
                  {user.serviceList.map((service, index) => (
                    <Typography key={`service-${index}`} sx={{ ml: '15px' }}>
                      {service.serviceName}
                    </Typography>
                  ))}
                </BodyCell>
                <BodyCell align="left">
                  <Typography sx={{ ml: '15px' }}>
                    {DateTime.fromSQL(user.createdAt ?? '').toFormat('dd.MM.yyyy')}
                  </Typography>
                </BodyCell>
                <BodyCell>
                  <Button
                    variant="contained"
                    onClick={() => putUser(user.id, user.state === 1 ? 0 : 1, index)}
                    sx={{
                      backgroundColor: user.state === 1 ? 'red' : 'green',
                      fontFamily: 'Roboto',
                      fontWeight: 400,
                      fontSize: '17px',
                      lineHeight: '24px',
                      textTransform: 'none'
                    }}>
                    {user.state === 1 ? 'Заблокировать' : 'Разблокировать'}
                  </Button>
                </BodyCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};
