import React, { Component } from 'react';
import { Alert, Button, Container, Grid } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import { io } from 'socket.io-client';
import { DateTime } from 'luxon';
import has from 'lodash/has';

import { ExecutorsListView } from './components/ExecutorsListView';
import { MessageListView } from './components/MessageListView';
import { cAvatarColorList } from './components/Avatar';
import { getAuthToken, removeAuthToken } from '../../services';
import env from '../../environment.json';
import { IInputChatMessage, IOutputChatMessage } from '../types';

// @ts-ignore
const socket = io(`${env[env.environment].socket}`, {
  transports: ['websocket'],
  autoConnect: false,
  auth: {}
});

socket.on('connect', () => {
  console.log('connect:', socket.connected);
});

socket.on('connect_error', error => {
  console.log('connect_error:', error);
  removeAuthToken();
  window.location.reload();
});

socket.io.on('error', error => {
  console.log(error);
});

export interface IChatMessage {
  id: number;
  root: boolean;
  author: string;
  colorIndex: number;
  value: string;
  time: string;
}

export interface IExecutor {
  data: IChatMessage[];
  id: string;
  pin: number;
  deviceID: string;
  userID: number | null;
  colorIndex: number;
  name: string | null;
  lastMessage: IChatMessage;
  approved: number;
}

interface Props {}

interface State {
  showAlert: boolean;
  requestHideId: number | null;
  selectedExecutor: number | null;
  executorsList: IExecutor[];
}

export class ChatExecutors extends Component<Props, State> {
  constructor(props: Readonly<Props> | Props) {
    super(props);
    this.state = {
      requestHideId: null,
      showAlert: false,
      executorsList: [],
      selectedExecutor: null
    };
  }

  componentDidMount() {
    if (!has(socket.auth, 'manager')) {
      const token = getAuthToken();
      if (token != null) {
        socket.auth = { manager: getAuthToken() };
        socket.connect();
      }
      socket.emit('chat_history', (error: any, data: any) => {
        if (error == null) {
          this.onParseHistory(data);
        } else {
          console.log(error);
        }
      });
      socket.on('receive_chat_message', () => {
        socket.emit('chat_history', (error: any, data: any) => {
          if (error == null) {
            this.onParseHistory(data);
          } else {
            console.log(error);
          }
        });
      });
    }
  }

  onParseHistory = (data: IOutputChatMessage[]) => {
    const executorsList: IExecutor[] = [];
    const idToIndex: Record<string, number> = {};
    data
      .sort((a, b) => {
        // if (a.pin !== b.pin) {
        //   return a.pin < b.pin ? 1 : -1;
        // }
        return DateTime.fromISO(a.createdAt).toMillis() < DateTime.fromISO(b.createdAt).toMillis() ? 1 : -1;
      })
      .forEach(obj => {
        const value = obj.value.trim();
        const dateTime = DateTime.fromISO(obj.createdAt);

        let id = this.parseUserID(obj);
        if (
          id != null &&
          id.trim().length > 0 &&
          value.length > 0 //&& topic.length > 0//*
        ) {
          id = id.trim();
          let index = 0;
          if (!has(idToIndex, id)) {
            idToIndex[id] = executorsList.length;
            executorsList.push(this.parseUser(obj, id, dateTime));
          }
          index = idToIndex[id];
          executorsList[index].data.unshift(this.parseMessage(obj, executorsList[index].colorIndex, dateTime));
        }
      });
    this.setState(() => ({
      executorsList
    }));
  };

  parseUserID = (obj: IOutputChatMessage): string => `userID-${obj.userId}`;

  parseUser = (obj: IOutputChatMessage, id: string, dateTime: DateTime): IExecutor => {
    let colorIndex = 0;
    for (let i = 0; i < id.length; ++i) {
      colorIndex += id.charCodeAt(i);
    }
    colorIndex %= cAvatarColorList.length;
    return {
      id,
      pin: 0,
      deviceID: '',
      userID: obj.userId,
      colorIndex,
      name: obj.userFullName,
      lastMessage: this.parseMessage(obj, colorIndex, dateTime),
      data: [],
      approved: obj.approved
    };
  };

  parseMessage = (obj: IOutputChatMessage, colorIndex: number, dateTime: DateTime): IChatMessage => ({
    id: obj.id,
    root: obj.userIdFrom === -1,
    author: obj.userIdFrom !== -1 ? obj.userFullName : 'Вы',
    colorIndex,
    value: obj.value,
    time:
      dateTime.toISOWeekDate() === DateTime.now().toISOWeekDate()
        ? dateTime.toFormat('HH:mm')
        : dateTime.toFormat('dd.MM.yyyy')
  });

  onSend = (value: string) => {
    const { selectedExecutor, executorsList } = this.state;
    if (selectedExecutor != null) {
      const data: IInputChatMessage = {
        state: 0,
        userIdFrom: -1,
        userIdTo: executorsList[selectedExecutor].userID ?? 1,
        value
      };
      socket.emit('chat_message', data, (error: Error, _success: boolean) => {
        if (error != null) {
          console.log('onSend:', error);
        } else {
          socket.emit('chat_history', (error: any, data: any) => {
            if (error == null) {
              this.onParseHistory(data);
            } else {
              console.log(error);
            }
          });
        }
      });
    }
  };

  onChangeExecutor = (index: number) => this.setState(() => ({ selectedExecutor: index }));

  onHideExecutor = (index: number) => this.setState(() => ({ showAlert: true, requestHideId: index }));

  onCommitHideClient = () => {
    if (this.state.requestHideId != null) {
      socket.emit('hide', this.state.executorsList[this.state.requestHideId].deviceID);
      const newExecutorsList = [...this.state.executorsList];
      newExecutorsList.splice(this.state.requestHideId, 1);
      this.setState(() => ({
        showAlert: false,
        requestHideId: null,
        executorsList: newExecutorsList,
        selectedExecutor: null,
        selectedTopic: null
      }));
    }
  };

  onPinExecutor = (userID: number | null) => {
    if (userID != null) {
      socket.emit('pin', userID, (error: Error, success: boolean) => {
        console.log(error, ':', success);
        if (error == null) {
          socket.emit('executor_history', (error: any, data: any) => {
            if (error == null) {
              this.onParseHistory(data);
            } else {
              console.log(error);
            }
          });
        }
      });
    }
  };

  onUnPinExecutor = (userID: number | null) => {
    if (userID != null) {
      socket.emit('unpin', userID, (error: Error, success: boolean) => {
        if (error == null) {
          socket.emit('executor_history', (error: any, data: any) => {
            if (error == null) {
              this.onParseHistory(data);
            } else {
              console.log(error);
            }
          });
        }
      });
    }
  };

  onApprove = (userID: number | null) => {
    if (userID != null) {
      socket.emit('approve', userID, (error: Error, success: boolean) => {
        if (error == null) {
          this.onSend('Поздравляем. Вы одобрены и можете принимать заказы в нашем сервисе!');
        }
      });
    }
  };

  render() {
    const { executorsList, selectedExecutor, showAlert } = this.state;
    return (
      <StyledEngineProvider injectFirst>
        <Container maxWidth={false} className="chatContainer">
          {showAlert && (
            <Alert
              variant="filled"
              severity="error"
              action={
                <>
                  <Button color="inherit" size="small" onClick={this.onCommitHideClient}>
                    OK
                  </Button>
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => this.setState(() => ({ showAlert: false, requestHideId: null }))}>
                    Cancel
                  </Button>
                </>
              }>
              Точно ли хотите удалить чат
            </Alert>
          )}
          <Grid container>
            <Grid item xs={6} md={6} lg={6}>
              <ExecutorsListView
                data={executorsList}
                selectedExecutor={selectedExecutor != null ? selectedExecutor : null}
                onChangeExecutor={this.onChangeExecutor}
                onHideExecutor={this.onHideExecutor}
                onPinExecutor={this.onPinExecutor}
                onUnPinExecutor={this.onUnPinExecutor}
              />
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <MessageListView
                data={executorsList}
                selectedExecutor={selectedExecutor != null ? selectedExecutor : null}
                onSend={this.onSend}
                onApprove={this.onApprove}
              />
            </Grid>
          </Grid>
        </Container>
      </StyledEngineProvider>
    );
  }
}
