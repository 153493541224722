import { axiosInstance } from '../features/app';
import env from '../environment.json';
import { AxiosError, AxiosResponse } from 'axios';
import { IAuthorizedUser, ICategory, ICountry, IService, IUnauthorizedUser } from '../features/types';

export const getAuthToken = (): null | string => window.localStorage.getItem('letsgoglobal-token');

export const setAuthToken = (token: string) => window.localStorage.setItem('letsgoglobal-token', token);

export const removeAuthToken = () => window.localStorage.removeItem('letsgoglobal-token');

export const setUserInfo = (payload: object) =>
  window.localStorage.setItem('letsgoglobal-user', JSON.stringify(payload));

export const getUserInfo = () => {
  const info = window.localStorage.getItem('letsgoglobal-user');
  return info != null ? JSON.parse(info) : null;
};

export const axiosGetUnauthorizedUserList = (callback: (data: IUnauthorizedUser[]) => void) => {
  axiosInstance
    // @ts-ignore
    .get(`${env[env.environment].host}/v1/user/unauthorized/list`)
    .then(response => {
      callback(response.data);
    })
    .catch(err => {
      console.log(err);
      if (err instanceof AxiosError && err.response?.status === 401) {
        removeAuthToken();
        window.location.reload();
      }
      callback([]);
    });
};

export const axiosPostUnauthorizedUser = (callback: (status: boolean) => void) => {
  axiosInstance
    // @ts-ignore
    .post(`${env[env.environment].host}/v1/user/unauthorized`)
    .then(response => {
      callback(response.data);
    })
    .catch(err => {
      console.log(err);
      if (err instanceof AxiosError && err.response?.status === 401) {
        removeAuthToken();
        window.location.reload();
      }
      callback(false);
    });
};

export const axiosPutUnauthorizedUser = (user: IUnauthorizedUser, callback: (status: boolean) => void) => {
  axiosInstance
    // @ts-ignore
    .put(`${env[env.environment].host}/v1/user/unauthorized?id=${user.id}`, user)
    .then(response => {
      callback(response.data);
    })
    .catch(err => {
      console.log(err);
      if (err instanceof AxiosError && err.response?.status === 401) {
        removeAuthToken();
        window.location.reload();
      }
      callback(false);
    });
};

export const axiosGetCategoryListWithLocale = (callback: (data: ICategory[]) => void) => {
  axiosInstance
    // @ts-ignore
    .get(`${env[env.environment].host}/v1/service/list/category/locale`)
    .then(response => {
      callback(response.data);
    })
    .catch(err => {
      console.log(err);
      if (err instanceof AxiosError && err.response?.status === 401) {
        removeAuthToken();
        window.location.reload();
      }
      callback([]);
    });
};

export const axiosGetService = (
  id: string | undefined,
  lang: string,
  callback: (data: Record<string, any>) => void
) => {
  axiosInstance
    // @ts-ignore
    .get(`${env[env.environment].host}/v1/service?id=${id}&language=${lang}`)
    .then(response => {
      callback(response);
    })
    .catch(err => {
      console.log(err);
      callback({});
    });
};

export const axiosGetServiceListLocale = (callback: (data: IService[]) => void) => {
  axiosInstance
    // @ts-ignore
    .get(`${env[env.environment].host}/v1/service/list/short/locale`)
    .then(response => {
      callback(response.data);
    })
    .catch(error => {
      console.log(error);
      if (error instanceof AxiosError && error.response?.status === 401) {
        removeAuthToken();
        window.location.reload();
      }
    });
};

const cCityListURL = '/v1/authorization/cities';
export const getCountryList = async (language: string): Promise<ICountry[]> => {
  let result: ICountry[] = [];
  try {
    const response: AxiosResponse<ICountry[]> = await axiosInstance.get(
      // @ts-ignore
      `${env[env.environment].host}${cCityListURL}?language=${language}`
    );
    result = [...response.data];
  } catch (error) {
    console.log(error);
  }
  return result;
};

export const axiosGetAuthorizedUserList = (callback: (data: IAuthorizedUser[]) => void) => {
  axiosInstance
    // @ts-ignore
    .get(`${env[env.environment].host}/v1/user/authorized/list`)
    .then(response => {
      callback(response.data);
    })
    .catch(err => {
      console.log(err);
      if (err instanceof AxiosError && err.response?.status === 401) {
        removeAuthToken();
        window.location.reload();
      }
      callback([]);
    });
};

export const axiosPutAuthorizedUser = (id: number, state: number, callback: (status: boolean) => void) => {
  axiosInstance
    // @ts-ignore
    .put(`${env[env.environment].host}/v1/user/authorized?id=${id}`, { state: state })
    .then(response => {
      callback(response.data);
    })
    .catch(err => {
      console.log(err);
      if (err instanceof AxiosError && err.response?.status === 401) {
        removeAuthToken();
        window.location.reload();
      }
      callback(false);
    });
};
