import React, { PureComponent } from 'react';
import { Box, IconButton, TextareaAutosize } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { StyledEngineProvider } from '@mui/material/styles';

// import { Message } from './components/Message';
import { IExecutor } from '../../index';
import './index.css';
import { Message } from './components/Message';
import { ApproveButton } from '../ApproveButton';

interface Props {
  selectedExecutor: number | null;
  data: IExecutor[];
  onSend: (value: string) => void;
  onApprove: (userID: number | null) => void;
}

interface State {
  offset: number;
  value: string;
}

export class MessageListView extends PureComponent<Props, State> {
  messagesEndRef = React.createRef();

  constructor(props: Props) {
    super(props);
    this.state = {
      offset: 28 + 16 * 2,
      value: ''
    };
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom = () => {
    // @ts-ignore
    this.messagesEndRef.current?.scrollIntoView({ block: 'end', inline: 'nearest' });
  };

  onChange = (event: any) => {
    this.setState(() => ({ value: event.target.value, offset: event.target.scrollHeight + 16 * 2 }));
  };

  onSend = () => {
    this.props.onSend(this.state.value);
    this.setState(() => ({ value: '' }));
  };

  render() {
    const { offset } = this.state;
    const { selectedExecutor, data } = this.props;
    const messages = selectedExecutor != null ? data[selectedExecutor].data : [];
    const isApproved = selectedExecutor != null ? data[selectedExecutor].approved > 0 : false;
    const executorID = selectedExecutor != null ? data[selectedExecutor].userID : 0;

    return messages.length > 0 ? (
      <StyledEngineProvider injectFirst>
        <Box className="messageListViewContainer">
          <Box className="header">
            <ApproveButton isApproved={isApproved} onClick={() => this.props.onApprove(executorID)} />
          </Box>
          <Box
            className="messageView"
            sx={{ height: `calc(100vh - 65px - 32px - 29px - ${offset}px)`, overflow: 'auto' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', mt: 1 }} ref={this.messagesEndRef}>
              {messages.map((message: any) => (
                <Message
                  key={`Message-${message.id}`}
                  colorIndex={message.colorIndex}
                  author={message.author}
                  message={message.value}
                  created={message.time}
                  root={message.root}
                />
              ))}
            </Box>
          </Box>
          <Box className="textAriaBox">
            <TextareaAutosize
              className="textAriaAutosize"
              onChange={this.onChange}
              value={this.state.value}
              minRows={1}
              id="message-input"
              placeholder="Введите сообщение"
            />
            <IconButton className="iconButton" onClick={this.onSend} color="primary" aria-label="add an alarm">
              <ArrowUpwardIcon sx={{ color: 'white' }} />
            </IconButton>
          </Box>
        </Box>
      </StyledEngineProvider>
    ) : (
      <StyledEngineProvider injectFirst>
        <Box className="isTopicNull"></Box>
      </StyledEngineProvider>
    );
  }
}
