import {styled, TableCell, tableCellClasses} from "@mui/material";

export const BodyCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.root}`]: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: '17px',
        lineHeight: '24px',
        color: '#1B1F3B',
        opacity: 0.8,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 16,
        paddingBottom: 16
    }
}));
