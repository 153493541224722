import React, { PureComponent } from 'react';
import { Box, Button, FormControl, MenuItem, Select, Typography } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import './index.css';
import '../../../../maneStyles.css';
import { IExecutor } from '../../../../index';
import { IOrder } from '../../../../../types';

interface Props {
  order: IOrder | null;
  topic: string;
  executorList: IExecutor[];
  onCreateOrder: (executorID: number) => void;
}

interface State {
  selectedExecutor: number;
}

export class Header extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedExecutor: this.props.order != null ? this.props.order.executorId : -1
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps.order !== this.props.order) {
      this.setState(() => ({
        selectedExecutor: this.props.order != null ? this.props.order.executorId : -1
      }));
    }
  }

  handleChange = (e: any) => {
    this.setState({ selectedExecutor: e.target.value });
  };

  onClick = () => this.props.onCreateOrder(this.state.selectedExecutor);

  render() {
    const { selectedExecutor } = this.state;
    return (
      <StyledEngineProvider injectFirst>
        <Box className="messageListViewHeader">
          <Typography className="topic colorMain">{this.props.topic}</Typography>
          <FormControl className="form">
            <Select
              className="select"
              variant="standard"
              id="executorsSelect"
              value={selectedExecutor}
              onChange={e => this.handleChange(e)}>
              {this.props.executorList.map(obj => (
                <MenuItem key={obj.id} value={obj.id}>
                  {obj.nickname}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            onClick={this.onClick}
            disabled={selectedExecutor === -1 || this.props.order != null}
            className={selectedExecutor === -1 || this.props.order != null ? 'disabled' : 'createOrder'}>
            Создать заказ
          </Button>
        </Box>
      </StyledEngineProvider>
    );
  }
}
