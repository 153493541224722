import React, { Component, FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, Checkbox, Container, FormControlLabel, MenuItem, TextField } from '@mui/material';
import { AxiosError } from 'axios';

import { axiosGetCategoryListWithLocale, axiosGetService } from '../../services';
import { axiosInstance } from '../app';
import env from '../../environment.json';
import { removeAuthToken } from '../../services';
import { ICategory, ILocale } from '../types';

interface IService {
  id: number;
  autoOrder: boolean;
  name: string;
  state: number;
  categoryID: number;
  category: string;
  manager: string;
  updated: string;
  locale: ILocale[];
}

interface Props {}

export const ServiceEditor: FC<Props> = (props: Props) => {
  const { id } = useParams();
  const cLanguages = ['ru', 'en', 'es', 'ca', 'fr'];
  const cDefaultLanguage = 'ru';
  const [language, setLanguage] = useState(cDefaultLanguage);
  const [service, setService] = useState<IService | null>(null);
  const [categoryList, setCategoryList] = useState<ICategory[]>([]);

  useEffect(() => {
    if (id != null) {
      axiosGetService(id, cDefaultLanguage, response => {
        setService(prev => ({
          ...prev,
          ...response.data
        }));
        axiosGetCategoryListWithLocale(categoryList => setCategoryList(categoryList));
      });
    }
  }, []);

  const onChangeLanguage = (value: string) => {
    setLanguage(value);
    axiosGetService(id, value, response =>
      setService(prev => ({
        ...prev,
        ...response.data
      }))
    );
  };

  const onChangeState = () =>
    service != null &&
    setService({
      ...service,
      state: service.state === 1 ? 0 : 1
    });

  const onChangeAutoOrder = () =>
    service != null &&
    setService({
      ...service,
      autoOrder: !service.autoOrder
    });

  const onChangeName = (e: any) =>
    service != null &&
    setService({
      ...service,
      name: e.target.value
    });

  const onChangeCategory = (value: string) =>
    service != null &&
    setService({
      ...service,
      categoryID: parseInt(value, 10)
    });

  const onSave = () => {
    axiosInstance
      .put(
        // @ts-ignore
        `${env[env.environment].host}/v1/service?id=${service?.id}&language=${language}`,
        service
      )
      .catch(error => {
        console.log(error);
        if (error instanceof AxiosError && error.response?.status === 401) {
          removeAuthToken();
          window.location.reload();
        }
      });
  };

  return (
    <Container
      maxWidth={false}
      style={{
        padding: '0 30px',
        marginTop: '100px',
        minWidth: '980px'
      }}>
      <Box>
        <TextField
          variant="standard"
          size="small"
          onChange={e => onChangeName(e)}
          value={service?.name ?? ''}
          sx={{ p: 0, width: '97ch', height: '24px' }}
        />
        <TextField
          sx={{ width: '10ch', ml: '20px', mr: '20px' }}
          margin="none"
          size="small"
          value={language}
          select
          variant="standard"
          onChange={event => onChangeLanguage(event.target.value)}>
          {cLanguages.map(lng => (
            <MenuItem value={lng} key={lng}>
              {lng}
            </MenuItem>
          ))}
        </TextField>
        {categoryList.length > 0 && (
          <TextField
            onChange={event => onChangeCategory(event.target.value)}
            value={service?.categoryID}
            size="small"
            select
            variant="standard">
            {categoryList.map(({ id, locale }) => (
              <MenuItem key={`category-${id}`} value={id}>
                {locale[0].value}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Box>

      <FormControlLabel
        sx={{ mt: '20px' }}
        label="Сервис для подрядчиков(если не выделить - будет показываться оценка)"
        control={<Checkbox checked={service?.autoOrder ?? false} onChange={onChangeAutoOrder} />}
      />
      <FormControlLabel
        sx={{ mt: '20px' }}
        label="Скрыть сервис"
        control={<Checkbox checked={service?.state === 0} onChange={onChangeState} />}
      />

      <Box sx={{ display: 'flex', justifyContent: 'center', mt: '50px' }} onClick={onSave}>
        <Button variant="contained">Сохранить</Button>
      </Box>
    </Container>
  );
};
