import React, { PureComponent } from 'react';
import { Box, Typography } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import './index.css';
import '../../../chat/maneStyles.css';

export const cAvatarColorList: string[] = [
  '#A8CEF1',
  '#3682DB',
  '#8DDA71',
  '#34B41F',
  '#E29398',
  '#B8474E',
  '#FCC068',
  '#FF8A00',
  '#DAB3F9',
  '#7B439E',
  '#FEE797',
  '#FCBB14',
  '#EA97C4',
  '#BD65A4',
  '#7FD7CC',
  '#2FAD96',
  '#D4ACA2',
  '#9D6F64',
  '#D2E9A2',
  '#AADC42',
  '#A0C5DF'
];

interface Props {
  colorIndex: number;
  name: string | null;
  selected: boolean;
  onClick: () => void;
}

export class Avatar extends PureComponent<Props> {
  render() {
    const { colorIndex, name, selected, onClick } = this.props;
    return (
      <StyledEngineProvider injectFirst>
        <Box
          className="avatarContainerExecutors"
          onClick={onClick}
          sx={{ background: selected ? '#526ED3' : 'transparent' }}>
          <Box
            className="avatar"
            sx={{
              background: `${cAvatarColorList[colorIndex]}`,
              borderRadius: selected ? '8px' : '36px'
            }}>
            <Typography className="text">{name != null && name.length > 0 && name[0]}</Typography>
          </Box>
        </Box>
      </StyledEngineProvider>
    );
  }
}
