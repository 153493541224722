import React, { PureComponent } from 'react';
import { Box, Typography } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';

import { TopicIcon } from '../TopicIcon';
import './index.css';
import '../../maneStyles.css';

interface Props {
  selected: boolean;
  topic: string;
  author: string;
  message: string;
  time: string;
  onClick: () => void;
}

export class TopicRow extends PureComponent<Props> {
  render() {
    const { selected, topic, author, message, time, onClick } = this.props;
    return (
      <StyledEngineProvider injectFirst>
        <Box
          className="topicRowContainer hover"
          sx={{ backgroundColor: selected ? '#526ED3' : '#ffffff' }}
          onClick={onClick}>
          <TopicIcon name={topic} />
          <Box className="contentBox">
            <Typography
              className="topic"
              sx={{
                color: selected ? '#ffffff' : '#1B1F3B',
                opacity: selected ? 1 : 0.8
              }}>
              {topic}
            </Typography>
            <Box className="messageBox">
              <Typography className="author text15" sx={{ color: selected ? '#ffffff' : '#526ED3' }}>
                {author}:&nbsp;
              </Typography>
              <Typography
                className="text15 message"
                sx={{
                  color: selected ? '#ffffff' : '#1B1F3B',
                  opacity: selected ? 1 : 0.65
                }}>
                {message}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ ml: 2 }}>
            <Typography
              className="text15"
              sx={{
                color: selected ? '#ffffff' : '#1B1F3B',
                opacity: selected ? 1 : 0.4
              }}>
              {time}
            </Typography>
          </Box>
        </Box>
      </StyledEngineProvider>
    );
  }
}
