import React, { Component } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, Checkbox, Container, FormControlLabel, MenuItem, TextField } from '@mui/material';

import { getCategoryLanguage, putCategoryLanguage } from './services';

interface Props {
  id: string | undefined;
}

interface State {
  id: number;
  categoryName: string;
  languages: string[];
  language: string;
  state: 0 | 1;
}

export class CategoryEditorConnect extends Component<Props, State> {
  cLanguages = ['ru', 'en', 'es', 'ca', 'fr'];
  cDefaultLanguage = 'ru';
  constructor(props: Props) {
    super(props);

    this.state = {
      id: parseInt(this.props.id ?? '-1', 10),
      categoryName: '',
      languages: this.cLanguages,
      language: this.cDefaultLanguage,
      state: 1
    };
  }
  componentDidMount() {
    getCategoryLanguage(this.state.id, this.state.language, data =>
      this.setState({ categoryName: data.name, state: data.state })
    );
  }

  onChangeLanguage = (value: string) => {
    this.setState({ language: value }, () => {
      getCategoryLanguage(this.state.id, this.state.language, data => {
        if (data.name !== null) {
          this.setState({ categoryName: data.name });
        } else this.setState({ categoryName: '' });
      });
    });
  };

  onToggleChecked = () => {
    this.setState(prev => ({
      state: prev.state === 0 ? 1 : 0
    }));
  };

  render() {
    const { categoryName, language, languages, state, id } = this.state;
    return (
      <Container
        maxWidth={false}
        style={{
          padding: '0 30px',
          marginTop: '100px',
          minWidth: '980px'
        }}>
        <Box>
          <TextField
            variant="standard"
            size="small"
            onChange={e => {
              this.setState({ categoryName: e.target.value });
            }}
            value={categoryName}
            sx={{ p: 0, width: '97ch', height: '24px' }}
          />
          <TextField
            sx={{ width: '10ch', ml: '20px' }}
            margin="none"
            size="small"
            value={language}
            select
            variant="standard"
            onChange={event => this.onChangeLanguage(event.target.value)}>
            {languages.map(language => (
              <MenuItem value={language} key={language}>
                {language}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <FormControlLabel
          sx={{ mt: '20px' }}
          control={<Checkbox />}
          label="Скрыть категорию"
          checked={state === 0}
          onChange={this.onToggleChecked}
        />
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: '50px' }}>
          <Button variant="contained" onClick={() => putCategoryLanguage(id, language, categoryName, state)}>
            Сохранить
          </Button>
        </Box>
      </Container>
    );
  }
}
export const CategoryEditor = () => {
  const { id } = useParams();
  return <CategoryEditorConnect id={id} />;
};
