import React, { PureComponent } from 'react';
import { Button, Typography } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import './index.css';

interface Props {
  onClick: () => void;
  isApproved: boolean;
}

interface State {}

export class ApproveButton extends PureComponent<Props, State> {
  render() {
    const { isApproved, onClick } = this.props;
    return (
      <StyledEngineProvider injectFirst>
        <Button
          disabled={isApproved}
          className="button"
          sx={{
            backgroundColor: !isApproved ? 'green' : '#c3c3c3',
            '&:hover': {
              backgroundColor: !isApproved ? 'green' : '#c3c3c3'
            }
          }}
          onClick={onClick}>
          <Typography sx={{ color: !isApproved ? 'black' : '#a6a6a6' }}>Approve</Typography>
        </Button>
      </StyledEngineProvider>
    );
  }
}
