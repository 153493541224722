import React, { Component } from 'react';
import {
  Box,
  Button,
  Container,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { DateTime } from 'luxon';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { axiosInstance } from '../app';
import env from '../../environment.json';
import { AxiosError } from 'axios';
import { removeAuthToken } from '../../services';
import { v4 as uuidV4 } from 'uuid';

export const HeaderCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.root}`]: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '17px',
    lineHeight: '24px',
    color: '#1B1F3B',
    opacity: 0.65,
    padding: '16px 8px'
  }
}));

export const BodyCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.root}`]: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '17px',
    lineHeight: '24px',
    color: '#1B1F3B',
    opacity: 0.8,
    padding: '10px 13px 8px 10px'
  }
}));

interface ICategory {
  id: number;
  locale: [
    {
      language: string;
      value: string;
    }
  ];
  manager: string | null;
  updated: string | null;
  state: number | undefined;
}
interface Props {}

interface State {
  data: ICategory[];
}

export class Service extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      data: []
    };
  }

  componentDidMount() {
    axiosInstance
      // @ts-ignore
      .get(`${env[env.environment].host}/v1/service/list/short/locale`)
      .then(response => this.setState(() => ({ data: response.data })))
      .catch(error => {
        console.log(error);
        if (error instanceof AxiosError && error.response?.status === 401) {
          removeAuthToken();
          window.location.reload();
        }
      });
  }

  onAddNew() {
    const data = {
      id: uuidV4(),
      categoryID: 1,
      category: 'Банковские услуги',
      icon: 'card',
      name: 'noname',
      topic: 'spain-9999',
      header: 'noname',
      children: [
        {
          id: uuidV4,
          text: [],
          type: 'Spacing16',
          index: 0,
          title: null,
          children: [],
          attention: false
        }
      ]
    };
    axiosInstance
      // @ts-ignore
      .post(`${env[env.environment].host}/v1/service?language=ru`, data)
      // @ts-ignore
      .then(() => axiosInstance.get(`${env[env.environment].host}/v1/service/list/short/locale`))
      .then(response => this.setState(() => ({ data: response.data })))
      .catch(error => {
        console.log(error);
        if (error instanceof AxiosError && error.response?.status === 401) {
          removeAuthToken();
          window.location.reload();
        }
      });
  }

  render() {
    return (
      <Container
        maxWidth={false}
        style={{
          padding: '0 30px',
          marginTop: '80px'
        }}>
        <TableContainer
          sx={{
            maxHeight: 'calc(100vh - 100px)',
            minHeight: 'calc(100vh - 100px)',
            overflow: 'auto',
            margin: 0
          }}>
          <Table>
            <TableHead
              sx={{
                backgroundColor: 'white',
                position: 'sticky',
                top: 0,
                zIndex: 1
              }}>
              <TableRow>
                <HeaderCell width="45%" align="left">
                  Название
                </HeaderCell>
                <HeaderCell width="20%" align="left">
                  Категория
                </HeaderCell>
                <HeaderCell width="20%" align="left">
                  Последнее редактирование
                </HeaderCell>
                <HeaderCell width="5%" align="left">
                  <Box sx={{ justifyContent: 'flex-end' }}>
                    <Button
                      sx={{ backgroundColor: 'blue' }}
                      variant="contained"
                      startIcon={<AddCircleOutlineIcon />}
                      onClick={() => this.onAddNew()}>
                      Добавить
                    </Button>
                  </Box>
                </HeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.data.map((row: Record<string, any>) => (
                <TableRow
                  key={row.id}
                  sx={{
                    backgroundColor: row.state === 0 ? '#D7D7D7' : 'white',
                    opacity: row.state === 0 ? 0.5 : 1
                  }}>
                  <BodyCell align="left">
                    {row.locale.map((language: Record<string, any>, index: number) => (
                      <Typography
                        key={language.value}
                        sx={
                          index === 0
                            ? { fontSize: 18, fontWeight: 600, color: 'black', opacity: 0.9 }
                            : { fontSize: 17, fontWeight: 400, color: 'black' }
                        }>
                        {index === 0 ? `${language.value}` : `[${language.language}] ${language.value}`}
                      </Typography>
                    ))}
                  </BodyCell>
                  <BodyCell align="left">{row.category}</BodyCell>
                  <BodyCell align="left">
                    {row.manager}&nbsp;{DateTime.fromSQL(row.updated).toFormat('dd.MM.yyyy')}
                  </BodyCell>
                  <BodyCell align="left">
                    <Stack direction="row" spacing={2}>
                      <Button
                        href={`service/${row.id}/editor`}
                        sx={{
                          fontFamily: 'Roboto',
                          fontWeight: 400,
                          fontSize: '17px',
                          lineHeight: '24px',
                          textTransform: 'none'
                        }}>
                        Редактировать
                      </Button>
                    </Stack>
                  </BodyCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    );
  }
}
