import React, { Component } from 'react';
import { Container, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { DateTime } from 'luxon';

import { io } from 'socket.io-client';
import env from '../../environment.json';
import { getAuthToken, removeAuthToken } from '../../services';
import has from 'lodash/has';
import {HeaderCell} from "../headerCell";
import {BodyCell} from "../bodyCell";

//@ts-ignore
const socket = io(`${env[env.environment].socket}`, {
  transports: ['websocket'],
  autoConnect: false,
  auth: {}
});

socket.on('connect', () => {
  console.log('connect:', socket.connected);
});

socket.on('connect_error', error => {
  console.log('connect_error:', error);
  removeAuthToken();
  window.location.reload();
});

socket.io.on('error', error => {
  console.log(error);
});

interface Props {}

interface State {
  orderList: any[];
}

export class Orders extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      orderList: []
    };
  }

  componentDidMount() {
    if (!has(socket.auth, 'manager')) {
      const token = getAuthToken();
      if (token != null) {
        socket.auth = { manager: getAuthToken() };
        socket.connect();
      }
      socket.emit('order_list', (error: any, data: any) => {
        if (error == null) {
          this.setState(() => ({ orderList: [...data] }));
        } else {
          console.log(error);
        }
      });
    }
  }

  render() {
    return (
      <Container maxWidth={false} sx={{ maxHeight: '100vh', marginTop: '80px' }}>
        <TableContainer
          sx={{
            border: '1px solid black',
            mt: '35px',
            maxHeight: 'calc(100vh - 100px)',
            minHeight: 'calc(100vh - 100px)',
            overflow: 'auto',
            p: '0 0 0 10px'
          }}>
          <Table>
            <TableHead
              sx={{
                backgroundColor: 'white',
                position: 'sticky',
                top: 0,
                zIndex: +1
              }}>
              <TableRow sx={{ borderBottom: '1px solid black' }}>
                <HeaderCell width="4%" align="left">
                  Id
                </HeaderCell>
                <HeaderCell width="26%" align="left">
                  Название услуги
                </HeaderCell>
                <HeaderCell width="26%" align="left">
                  Клиент
                </HeaderCell>
                <HeaderCell width="28%" align="left">
                  Подрядчик
                </HeaderCell>
                <HeaderCell width="16%" align="left">
                  Статус
                </HeaderCell>
                <HeaderCell width="16%" align="left">
                  Обновлен
                </HeaderCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ maxHeight: '100% -56.8px ' }}>
              {this.state.orderList.map(order => (
                <TableRow sx={{ backgroundColor: 'white' }} key={`order-id-${order.id}`}>
                  <BodyCell style={{ padding: '0 0 0 10px' }} align="left">
                    {order.id}
                  </BodyCell>
                  <BodyCell style={{ padding: '0' }} align="left">
                    {order.topic}
                  </BodyCell>
                  <BodyCell style={{ padding: '0' }} align="left">
                    {order.username}
                  </BodyCell>
                  <BodyCell style={{ padding: '0' }} align="left">
                    {order.executorName}
                  </BodyCell>
                  <BodyCell align="left">{order.state > 0 ? 'В работе' : 'Назначен'}</BodyCell>
                  <BodyCell align="left">{DateTime.fromISO(order.updated).toFormat('dd.MM.yyyy')}</BodyCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    );
  }
}
