import { AxiosError } from 'axios';

import { axiosInstance } from '../../app';
import env from '../../../environment.json';
import { removeAuthToken } from '../../../services';

export const getCategoryLocale = (callback: (data: any) => void) => {
  axiosInstance
    // @ts-ignore
    .get(`${env[env.environment].host}/v1/service/list/category/locale`)
    .then(response => callback(response.data))
    .catch(error => {
      console.log(error);
      if (error instanceof AxiosError && error.response?.status === 401) {
        removeAuthToken();
        window.location.reload();
      }
    });
};

export const postNewCategory = (callback: () => void) => {
  axiosInstance
    // @ts-ignore
    .post(`${env[env.environment].host}/v1/service/category`)
    .then(() => callback())
    .catch(err => {
      console.log(err);
    });
};
