import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, TextField, TableRow } from '@mui/material';
import React, { PureComponent } from 'react';
import { BodyCell } from '../../../bodyCell';


interface Props {
  keyValue: string;
  ru: string;
  en: string;
  es: string;
  fr: string;
  ca: string;
  id: string;
  onChange: (value: string, lang: string, id: string) => void;
  onDelete: (id: string) => void;
}

export class Row extends PureComponent<Props> {
  render() {
    const { keyValue, ru, en, es, fr, ca, id, onChange, onDelete } = this.props;
    return (
      <TableRow sx={{ backgroundColor: 'white' }}>
        <BodyCell style={{ padding: '0 0 0 10px' }} align="left">
          {keyValue}
        </BodyCell>
        <BodyCell style={{ padding: '0' }} align="left">
          <TextField
            value={ru}
            multiline
            variant="standard"
            InputProps={{ disableUnderline: true }}
            onChange={event => onChange(event.target.value, 'ru', id)}
            sx={{ width: '100%', p: 0 }}></TextField>
        </BodyCell>
        <BodyCell style={{ padding: '0' }} align="left">
          <TextField
            value={en}
            multiline
            variant="standard"
            InputProps={{ disableUnderline: true }}
            onChange={event => onChange(event.target.value, 'en', id)}
            sx={{ width: '100%', p: 0 }}></TextField>
        </BodyCell>
        <BodyCell style={{ padding: '0' }} align="left">
          <TextField
            value={es}
            multiline
            variant="standard"
            InputProps={{ disableUnderline: true }}
            onChange={event => onChange(event.target.value, 'es', id)}
            sx={{ width: '100%', p: 0 }}></TextField>
        </BodyCell>
        <BodyCell style={{ padding: '0' }} align="left">
          <TextField
            value={fr}
            multiline
            variant="standard"
            InputProps={{ disableUnderline: true }}
            onChange={event => onChange(event.target.value, 'fr', id)}
            sx={{ width: '100%', p: 0 }}></TextField>
        </BodyCell>
        <BodyCell style={{ padding: '0' }} align="left">
          <TextField
            value={ca}
            multiline
            variant="standard"
            InputProps={{ disableUnderline: true }}
            onChange={event => onChange(event.target.value, 'ca', id)}
            sx={{ width: '100%', p: 0 }}></TextField>
        </BodyCell>
        <BodyCell align="left">
          <IconButton disableRipple sx={{ color: '#db0d0d' }} onClick={() => onDelete(id)}>
            <DeleteIcon />
          </IconButton>
        </BodyCell>
      </TableRow>
    );
  }
}
