import React, { PureComponent } from 'react';
import { Box, Stack } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';

import { Avatar } from '../Avatar';
import { ExecutorsRow } from '../ExecutorRow';
import { IExecutor } from '../../index';
import './index.css';

interface Props {
  selectedExecutor: number | null;
  data: IExecutor[];
  onChangeExecutor: (index: number) => void;
  onHideExecutor: (index: number) => void;
  onPinExecutor: (userID: number | null) => void;
  onUnPinExecutor: (userID: number | null) => void;
}

export class ExecutorsListView extends PureComponent<Props> {
  render() {
    const { selectedExecutor, data, onChangeExecutor, onHideExecutor } = this.props;
    return (
      <StyledEngineProvider injectFirst>
        <Box sx={{ display: 'flex' }}>
          <Box className="executorsListMessageBox" sx={{ background: '#FFFFFF', width: '100%' }}>
            <Stack direction="column">
              {data.map((executor, index) => (
                <Avatar
                  key={`avatar-${executor.id}`}
                  colorIndex={executor.colorIndex}
                  selected={selectedExecutor === index}
                  name={executor.name}
                  onClick={() => onChangeExecutor(index)}
                />
              ))}
            </Stack>
            <Stack direction="column" flexGrow={2}>
              {data.map((executor, index) => (
                <ExecutorsRow
                  key={`executor-${executor.id}`}
                  userID={executor.userID}
                  deviceID={executor.deviceID}
                  name={executor.name}
                  isSelected={selectedExecutor === index}
                  author={executor.lastMessage.author}
                  message={executor.lastMessage.value}
                  time={executor.lastMessage.time}
                  pin={executor.pin}
                  onClick={() => onChangeExecutor(index)}
                  onPin={() => this.props.onPinExecutor(executor.userID)}
                  onUnPin={() => this.props.onUnPinExecutor(executor.userID)}
                />
              ))}
            </Stack>
          </Box>
        </Box>
      </StyledEngineProvider>
    );
  }
}
