import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { IUnauthorizedUser } from '../types';
import { axiosGetUnauthorizedUserList, axiosPostUnauthorizedUser } from '../../services';

export const HeaderCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.root}`]: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '17px',
    color: '#1B1F3B',
    opacity: 0.65,
    padding: '10px 8px 16px 8px'
  }
}));

export const BodyCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.root}`]: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '17px',
    lineHeight: '24px',
    color: '#1B1F3B',
    opacity: 0.8,
    padding: '10px 8px'
  }
}));

export const UnauthorizedContractors: FC = () => {
  const navigate = useNavigate();
  const [userList, setUserList] = useState<IUnauthorizedUser[]>();

  useEffect(() => {
    axiosGetUnauthorizedUserList(data => setUserList(data));
  }, []);

  const onAddUser = () => {
    axiosPostUnauthorizedUser(status => {
      axiosGetUnauthorizedUserList(data => setUserList(data));
    });
  };

  return (
    <Container
      maxWidth={false}
      style={{
        padding: '0 30px',
        marginTop: '90px'
      }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          sx={{ backgroundColor: 'blue' }}
          variant="contained"
          startIcon={<AddCircleOutlineIcon />}
          onClick={onAddUser}>
          Добавить
        </Button>
      </Box>
      <TableContainer
        sx={{
          maxHeight: 'calc(100vh - 100px)',
          minHeight: 'calc(100vh - 100px)',
          overflow: 'auto',
          margin: 0
        }}>
        <Table>
          <TableHead
            sx={{
              backgroundColor: 'white',
              position: 'sticky',
              top: 0,
              zIndex: 1
            }}>
            <TableRow>
              <HeaderCell width="16%" align="left">
                Фирма/Имя
              </HeaderCell>
              <HeaderCell width="16%" align="left">
                Контактные данные
              </HeaderCell>
              <HeaderCell width="6%" align="left">
                Страна
              </HeaderCell>
              <HeaderCell width="6%" align="left">
                Город
              </HeaderCell>
              <HeaderCell width="26%" align="left">
                Категории
              </HeaderCell>
              <HeaderCell width="26%" align="left">
                Сервисы
              </HeaderCell>
              <HeaderCell width="4%" align="left"></HeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userList?.map(user => (
              <TableRow
                sx={{
                  backgroundColor: user.state === 0 ? '#D7D7D7' : 'white',
                  opacity: user.state === 0 ? 0.5 : 1
                }}
                key={`user-${user.id}`}>
                <BodyCell align="left">
                  <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'black', opacity: 0.9 }}>
                    {user.name}
                  </Typography>
                </BodyCell>
                <BodyCell align="left">
                  <Typography>{user.info}</Typography>
                </BodyCell>
                <BodyCell align="left">
                  <Typography>{user.countryName}</Typography>
                </BodyCell>
                <BodyCell align="left">
                  <Typography>{user.availableNationwide ? 'По всей стране' : user.cityName}</Typography>
                </BodyCell>
                <BodyCell align="left">
                  <Typography>{user.categoryName}</Typography>
                </BodyCell>
                <BodyCell align="left">
                  <Typography>{user.serviceName}</Typography>
                </BodyCell>
                <BodyCell>
                  <Button
                    onClick={() => navigate(`/edit-contractor`, { state: { user } })}
                    sx={{
                      fontFamily: 'Roboto',
                      fontWeight: 400,
                      fontSize: '17px',
                      lineHeight: '24px',
                      textTransform: 'none'
                    }}>
                    Редактировать
                  </Button>
                </BodyCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};
