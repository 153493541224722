import React, { Component } from 'react';
import { Alert, Button, Container, Grid } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import { io } from 'socket.io-client';
import { DateTime } from 'luxon';
import has from 'lodash/has';

import { ClientListView } from './components/ClientListView';
import { MessageListView } from './components/MessageListView';
import { cAvatarColorList } from './components/Avatar';
import { getAuthToken, removeAuthToken } from '../../services';
import env from '../../environment.json';
import './index.css';
import { IInputOrderMessage, IOrder, IOutputOrderMessage } from '../types';

// @ts-ignore
const socket = io(`${env[env.environment].socket}`, {
  transports: ['websocket'],
  autoConnect: false,
  auth: {}
});

socket.on('connect', () => {
  console.log('connect:', socket.connected);
});

socket.on('connect_error', error => {
  console.log('connect_error:', error);
  removeAuthToken();
  window.location.reload();
});

socket.io.on('error', error => {
  console.log(error);
});

export interface IChatMessage {
  id: number;
  root: boolean;
  topic: string;
  author: string;
  colorIndex: number;
  value: string;
  time: string;
}

export interface IChatTopic {
  id: string;
  name: string;
  data: IChatMessage[];
  order: IOrder | null;
}

export interface IClient {
  id: string;
  pin: number;
  deviceID: string;
  userID: number | null;
  colorIndex: number;
  name: string | null;
  lastMessage: IChatMessage;
  topicList: Record<string, IChatTopic>;
}
export interface IExecutor {
  id: number;
  nickname: string;
}

interface Props {}

interface State {
  showAlert: boolean;
  requestHideId: number | null;
  selectedClient: number | null;
  selectedTopic: string | null;
  clientList: IClient[];
  executorList: IExecutor[];
}

export class Chat extends Component<Props, State> {
  constructor(props: Readonly<Props> | Props) {
    super(props);
    this.state = {
      requestHideId: null,
      showAlert: false,
      clientList: [],
      executorList: [],
      selectedClient: null,
      selectedTopic: null
    };
  }

  componentDidMount() {
    if (!has(socket.auth, 'manager')) {
      const token = getAuthToken();
      if (token != null) {
        socket.auth = { manager: getAuthToken() };
        socket.connect();
      }
      socket.emit('dashboard_order_history', (error: any, data: any) => {
        if (error == null) {
          this.onParseHistory(data);
        } else {
          console.log(error);
        }
      });
      socket.emit('executor_list', (error: any, data: any) => {
        if (error == null) {
          this.setState(() => ({ executorList: [...data] }));
        } else {
          console.log(error);
        }
      });
      socket.on('receive_order_message', () => {
        socket.emit('dashboard_order_history', (error: any, data: any) => {
          if (error == null) {
            this.onParseHistory(data);
          } else {
            console.log(error);
          }
        });
      });
    }
  }

  onParseHistory = (data: IOutputOrderMessage[]) => {
    const clientList: IClient[] = [];
    const idToIndex: Record<string, number> = {};
    data
      .sort((a, b) => {
        // if (a.pin !== b.pin) {
        //   return a.pin < b.pin ? 1 : -1;
        // }
        return DateTime.fromISO(a.createdAt).toMillis() < DateTime.fromISO(b.createdAt).toMillis() ? 1 : -1;
      })
      .forEach(obj => {
        const value = obj.value.trim();
        const topic = `${obj.serviceFullName}(${obj.contractorFullName})`;
        const dateTime = DateTime.fromISO(obj.createdAt);

        let id = this.parseUserID(obj);
        if (id != null && id.trim().length > 0 && value.length > 0 && topic.length > 0) {
          id = id.trim();
          let index = 0;
          if (!has(idToIndex, id)) {
            idToIndex[id] = clientList.length;
            clientList.push(this.parseUser(obj, id, dateTime));
          }
          index = idToIndex[id];
          clientList[index].name = obj.userFullName;
          if (topic.length > 0) {
            if (!has(clientList[index].topicList, topic)) {
              clientList[index].topicList[topic] = {
                id: topic,
                name: topic,
                data: [],
                order:
                  obj.orderId != null
                    ? {
                        id: obj.orderId,
                        userId: obj.userId,
                        serviceId: obj.serviceId,
                        executorId: obj.contactorId,
                        executorUsername: obj.contractorFullName,
                        state: obj.orderState
                      }
                    : null
              };
            }
            clientList[index].topicList[topic].data.unshift(
              this.parseMessage(obj, clientList[index].colorIndex, dateTime)
            );
          }
        }
      });
    this.setState(() => ({
      clientList
    }));
  };

  parseUserID = (obj: IOutputOrderMessage): string => `userID-${obj.userId}`;

  parseUser = (obj: IOutputOrderMessage, id: string, dateTime: DateTime): IClient => {
    let colorIndex = 0;
    for (let i = 0; i < id.length; ++i) {
      colorIndex += id.charCodeAt(i);
    }
    colorIndex %= cAvatarColorList.length;
    return {
      id,
      pin: 0,
      deviceID: '',
      userID: obj.userId,
      colorIndex,
      name: 'noname',
      topicList: {},
      lastMessage: this.parseMessage(obj, colorIndex, dateTime)
    };
  };

  parseMessage = (obj: IOutputOrderMessage, colorIndex: number, dateTime: DateTime): IChatMessage => ({
    id: obj.id,
    root: obj.senderId === -1,
    topic: obj.serviceFullName,
    author: obj.senderId === -1 ? 'Вы' : obj.sendFullName,
    colorIndex,
    value: obj.value,
    time:
      dateTime.toISOWeekDate() === DateTime.now().toISOWeekDate()
        ? dateTime.toFormat('HH:mm')
        : dateTime.toFormat('dd.MM.yyyy')
  });

  onSend = (value: string) => {
    const { selectedClient, selectedTopic, clientList } = this.state;
    if (selectedClient != null && selectedTopic != null) {
      const topic = clientList[selectedClient].topicList[selectedTopic];
      const data: IInputOrderMessage = {
        state: 0,
        orderId: topic.order != null ? topic.order.id : null,
        serviceId: topic.order != null ? topic.order.serviceId : null,
        userIdFrom: -1,
        contractorId: topic.order != null ? topic.order.executorId : null,
        value
      };
      socket.emit('order_message', data, (error: Error, _success: boolean) => {
        if (error != null) {
          console.log('onSendOrderMessage:', error);
        } else {
          socket.emit('dashboard_order_history', (error: any, data: any) => {
            if (error == null) {
              this.onParseHistory(data);
            } else {
              console.log(error);
            }
          });
        }
      });
    }
  };

  onChangeClient = (index: number) => this.setState(() => ({ selectedClient: index, selectedTopic: null }));

  onResetClient = () => this.setState(() => ({ selectedClient: null, selectedTopic: null }));

  onChangeTopic = (id: string) => this.setState(() => ({ selectedTopic: id }));

  onHideClient = (index: number) => this.setState(() => ({ showAlert: true, requestHideId: index }));

  onCommitHideClient = () => {
    if (this.state.requestHideId != null) {
      socket.emit('hide', this.state.clientList[this.state.requestHideId].deviceID);
      const newClientList = [...this.state.clientList];
      newClientList.splice(this.state.requestHideId, 1);
      this.setState(() => ({
        showAlert: false,
        requestHideId: null,
        clientList: newClientList,
        selectedClient: null,
        selectedTopic: null
      }));
    }
  };

  onPinClient = (userID: number | null) => {
    if (userID != null) {
      socket.emit('pin', userID, (error: Error, success: boolean) => {
        console.log(error, ':', success);
        if (error == null) {
          socket.disconnect();
          socket.connect();
        }
      });
    }
  };

  onUnPinClient = (userID: number | null) => {
    if (userID != null) {
      socket.emit('unpin', userID, (error: Error, success: boolean) => {
        console.log(error, ':', success);
        if (error == null) {
          socket.disconnect();
          socket.connect();
        }
      });
    }
  };

  onCreateOrder = (executorId: number) => {
    const { clientList, selectedClient, selectedTopic } = this.state;
    if (selectedClient != null && selectedTopic != null) {
      const userID = clientList[selectedClient].userID;
      socket.emit('order_create', userID, selectedTopic, executorId, (error: Error, result: number) => {
        console.log('create order - error:', error, '; result:', result, '.');
        if (error == null) {
          socket.emit('client_history', (error: any, data: any) => {
            if (error == null) {
              this.onParseHistory(data);
            } else {
              console.log(error);
            }
          });
        }
      });
    }
  };

  render() {
    const { clientList, selectedClient, selectedTopic, showAlert } = this.state;
    return (
      <StyledEngineProvider injectFirst>
        <Container maxWidth={false} className="chatContainer">
          {showAlert && (
            <Alert
              variant="filled"
              severity="error"
              action={
                <>
                  <Button color="inherit" size="small" onClick={this.onCommitHideClient}>
                    OK
                  </Button>
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => this.setState(() => ({ showAlert: false, requestHideId: null }))}>
                    Cancel
                  </Button>
                </>
              }>
              Точно ли хотите удалить чат
            </Alert>
          )}
          <Grid container>
            <Grid item xs={6} md={6} lg={6} className="grid1">
              <ClientListView
                data={clientList}
                selectedClient={selectedClient != null ? selectedClient : null}
                selectedTopic={selectedTopic}
                onChangeClient={this.onChangeClient}
                onResetClient={this.onResetClient}
                onChangeTopic={this.onChangeTopic}
                onHideClient={this.onHideClient}
                onPinClient={this.onPinClient}
                onUnPinClient={this.onUnPinClient}
              />
            </Grid>
            <Grid item xs={6} md={6} lg={6} className="grid1">
              <MessageListView
                onCreateOrder={this.onCreateOrder}
                executorList={this.state.executorList}
                data={this.state.clientList}
                selectedClient={selectedClient != null ? selectedClient : null}
                selectedTopic={selectedTopic}
                onSend={this.onSend}
              />
            </Grid>
          </Grid>
        </Container>
      </StyledEngineProvider>
    );
  }
}
