import { axiosInstance } from '../../app';
import env from '../../../environment.json';

export const getCategoryLanguage = (id: number, lang: string, callback: (data: Record<string, any>) => void | null) => {
  axiosInstance
    // @ts-ignore
    .get(`${env[env.environment].host}/v1/service/category?id=${id}&language=${lang}`)
    .then(response => callback(response.data))
    .catch(err => {
      console.log(err);
    });
};

export const putCategoryLanguage = (id: number, lang: string, categoryName: string, state: number) => {
  axiosInstance
    .put(
      // @ts-ignore
      `${env[env.environment].host}/v1/service/category?id=${id}&language=${lang}`,
      {
        name: categoryName,
        state
      }
    )
    .catch(err => {
      console.log(err);
    });
};
